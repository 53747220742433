export const ENV: string = import.meta.env.MODE!;

export const API_URL: string = import.meta.env.VITE_API_URL!;

export const APP_URL: string = import.meta.env.VITE_URL!;

export const BRASS_CDN_URL = import.meta.env.VITE_BRASS_CDN_URL!;

export const BRASS_EVERVAULT_URL: string = import.meta.env.VITE_BRASS_EVERVAULT_URL!;

export const BRASS_PRIVACY_POLICY = import.meta.env.VITE_BRASS_PRIVACY_POLICY!;

export const BRASS_TERMS_CONDITIONS = import.meta.env.VITE_BRASS_TERMS_CONDITIONS!;

export const BRASS_CAPITAL_TERMS_CONDITIONS = import.meta.env.VITE_BRASS_CAPITAL_TERMS_CONDITIONS!;

export const BRASS_CARDS_TERMS_CONDITIONS = import.meta.env.VITE_BRASS_CARDS_TERMS_CONDITIONS!;

export const BRASS_BANKING_APP_IOS = import.meta.env.VITE_BRASS_BANKING_APP_IOS!;

export const BRASS_BANKING_APP_ANDROID = import.meta.env.VITE_BRASS_BANKING_APP_ANDROID!;

export const ENTERPRISE_ACCESS_KEYS: string = import.meta.env.VITE_ENTERPRISE_ACCESS_KEYS!;

export const CUSTOMER_REFERENCE_PREFIX: string = import.meta.env.VITE_CUSTOMER_REFERENCE_PREFIX!;

export const SENTRY_KEY = import.meta.env.VITE_SENTRY_PUBLIC_KEY;

export const SENTRY_ENV = import.meta.env?.VITE_SENTRY_ENV || ENV;

export const SENTRY_TRACES_SAMPLE_RATE = import.meta.env?.VITE_SENTRY_TRACES_SAMPLE_RATE
  ? Number(import.meta.env?.VITE_SENTRY_TRACES_SAMPLE_RATE)
  : 0;

export const SENTRY_RELAYS_SESSION_SAMPLE_RATE = import.meta.env
  ?.VITE_SENTRY_RELAYS_SESSION_SAMPLE_RATE
  ? Number(import.meta.env.VITE_SENTRY_RELAYS_SESSION_SAMPLE_RATE)
  : 0;

export const SENTRY_ON_ERROR_SAMPLE_RATE = import.meta.env?.VITE_SENTRY_ON_ERROR_SAMPLE_RATE
  ? Number(import.meta.env.VITE_SENTRY_ON_ERROR_SAMPLE_RATE)
  : 0;

export const HOMEPAGE_URL: string = import.meta.env.VITE_BRASS_HOMEPAGE_URL!;
export const FACEBOOK_APP_ID: string = import.meta.env.VITE_FACEBOOK_APP_ID!;
export const ONBOARDING_URL: string = import.meta.env.VITE_ONBOARDING_URL!;

export const API_KEY: string = import.meta.env.VITE_API_KEY!;

export const BEAMER_URL = import.meta.env.VITE_BEAMER_URL!;
export const BEAMER_PRODUCT_ID = import.meta.env.VITE_BEAMER_PRODUCT_ID!;
export const COOKIE_DOMAIN = import.meta.env.VITE_COOKIES_DOMAIN!;

export const PAYSTACK_API_KEY: string = import.meta.env.VITE_PAYSTACK_API_KEY!;

export const PAYSTACK_INLINE_SCRIPT_URL: string = import.meta.env.VITE_PAYSTACK_INLINE_SCRIPT_URL!;

export const FLUTTERWAVE_KE_API_KEY: string = import.meta.env.VITE_FLUTTERWAVE_KE_API_KEY!;

export const FLUTTERWAVE_INLINE_SCRIPT_URL: string = import.meta.env
  .VITE_FLUTTERWAVE_INLINE_SCRIPT_URL!;

export const TINGG_INLINE_SCRIPT_URL: string = import.meta.env.VITE_TINGG_INLINE_SCRIPT_URL!;

export const ALGOLIA_APP_ID: string = import.meta.env.VITE_ALGOLIA_APP_ID!;
export const ALGOLIA_SEARCH_KEY: string = import.meta.env.VITE_ALGOLIA_SEARCH_KEY!;

export const BRASS_APP_PERSIST_KEY = import.meta.env.VITE_BRASS_APP_PERSIST_KEY! || 'DEMO_KEY';

// Headway
export const HEADWAY_WIDGET_URL = import.meta.env.VITE_HEADWAY_WIDGET_URL!;
export const HEADWAY_ACCOUNT_ID_NG = import.meta.env.VITE_HEADWAY_ACCOUNT_ID_NG!;
export const HEADWAY_ACCOUNT_ID_KE = import.meta.env.VITE_HEADWAY_ACCOUNT_ID_KE!;

// External API docs
export const BRASS_API_DOCS_URL = 'https://docs.trybrass.com/reference';

// ANALYTICS
export const CUSTOMER_IO_WRITE_KEY = import.meta.env.VITE_CUSTOMER_IO_WRITE_KEY!;

export const BRASS_CMS_URL: string = import.meta.env.VITE_BRASS_CMS_URL!;

export const BRASS_PAGES_URL: string = import.meta.env.VITE_BRASS_PAGES_URL!;

export const BRASS_SALES_EMAIL: string = import.meta.env.VITE_BRASS_SALES_EMAIL!;

export const BRASS_MOBILE_APP_SCHEME: string = import.meta.env.VITE_BRASS_MOBILE_APP_SCHEME!;

export const MAX_IDLE_TIME_LIMIT = import.meta.env?.VITE_MAX_IDLE_TIME_LIMIT
  ? Number(import.meta.env?.VITE_MAX_IDLE_TIME_LIMIT)
  : 0;

export const AIRTABLE_API_KEY = import.meta.env.VITE_AIRTABLE_API_KEY!;

export const EBOOK_DOWNLOAD_LOG_BASE_KEY = import.meta.env.VITE_EBOOK_DOWNLOAD_LOG_BASE_KEY!;

export const CORPORATE_CARD_BETA_BASE_KEY = import.meta.env.VITE_CORPORATE_CARD_BETA_BASE_KEY!;

export const SAVINGS_EARLY_ACCESS_BASE_KEY = import.meta.env.VITE_SAVINGS_EARLY_ACCESS_BASE_KEY!;

export const LAUNCHPAD_API_URL: string = import.meta.env.VITE_LAUNCHPAD_API_URL!;

export const LAUNCHPAD_API_KEY: string = import.meta.env.VITE_LAUNCHPAD_API_KEY!;

export const LAUNCHPAD_APP_URL: string = import.meta.env.VITE_LAUNCHPAD_APP_URL!;

// Zendesk
export const ZENDESK_URL: string = import.meta.env.VITE_ZENDESK_URL!;
export const ZENDESK_URL_KEY: string = import.meta.env.VITE_ZENDESK_URL_KEY!;
export const ZENDESK_ID: string = import.meta.env.VITE_ZENDESK_ID!;

//Intl Payment
export const INTL_PAYMENT_MIN_AMOUNT: number = Number(
  import.meta.env.VITE_INTL_PAYMENT_MIN_AMOUNT!
);

// Google play store url
export const GOOGLE_PLAY_STORE_URL: string = import.meta.env.VITE_GOOGLE_PLAY_STORE_URL!;

// iOS app store url
export const IOS_APP_STORE_URL: string = import.meta.env.VITE_IOS_APP_STORE_URL!;

//  Android URL SCHEME
export const ANDROID_URL_SCHEME: string = import.meta.env.VITE_ANDROID_URL_SCHEME!;

// iOS URL SCHEME
export const IOS_URL_SCHEME: string = import.meta.env.VITE_IOS_URL_SCHEME!;
